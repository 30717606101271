const fr = {
  translation: {
    id_1_text: "STELLA MÉDICAL",
    id_1_title: "Les infirmières de STELLA offrent des soins de qualité ",
    id_2_title: "pour tous vos tests sanguins, vaccins et plus, directement à votre porte.",
    id_3_text: "NOS SERVICES",
    id_1_Services: "Soins post-partum",
    id_1_Services_title: "soutien à l'allaitement / consultation ",
    id_2_Services_title: "soutien et éducation pour nouveau-né ",
    id_3_Services_title: "soutien infirmier de nuit",
    id_1_Blood: "Tests sanguins",
    id_1_Blood_title: "prélèvement de sang ",
    id_2_Blood_title: "culture de selles et urine ",
    id_3_Blood_title: "dépistage du streptocoque",
    id_1_taking: "Soins de compagnie + Suivis",
    id_1_taking_title: "Axé sur le soutien émotionnel, ",
    id_2_taking_title: "l'interaction sociale, la surveillance et ",
    id_3_taking_title: "l'aide aux activités quotidiennes.",
    id_1_other: "autres services",
    id_1_other_title: "administration de médicaments",
    id_2_other_title: "",
    id_3_other_title: "",
    id_1_button: "en savoir plus",
    id_1_Stella_Fast: "Pourquoi utiliser Stella?",
    id_1_Fast: "Rapide",
    id_2_Fast: "Réduction des temps d'attente à l'hôpital, ",
    id_3_Fast: "répond aux besoins urgents et ",
    id_4_Fast: "améliore la santé globale",
    id_1_Easy: "Facile",
    id_2_Easy: "Accessible directement à votre ",
    id_3_Easy: "domicile et pratique pour ",
    id_4_Easy: "votre emploi du temps",
    id_1_Safe: "Sûr",
    id_2_Safe: "Géré par des infirmières expérimentées, ",
    id_3_Safe: "qui suivent les pratiques cliniques ",
    id_4_Safe: "les plus récentes.",
    id_2_button: "prendre rendez-vous",
    id_1_Footer: "contactez-nous",
    id_2_Footer: "N'hésitez pas à prendre rendez-vous en ligne ou à contacter ",
    id_3_Footer: "nous par téléphone si vous avez des questions. ",
    id_4_Footer: "514-797-7144",
    id_5_Footer: "info@stellamedic.com",
    id_1_About: "À propos de nous",
    id_2_About: "Notre équipe d'infirmières cliniciennes et de soignants attentionnés est dévouée à fournir des soins exceptionnels via notre service infirmier mobile. Avec une expertise couvrant l'urgence, la maternité, les soins à domicile et les soins gériatriques, nous offrons des services personnalisés de haute qualité adaptés aux besoins individuels. En offrant une large gamme de services adaptés aux besoins de chacun, nous veillons à ce que les patients reçoivent un soutien personnalisé et de haute qualité dans le confort de leur domicile ou partout où ils en ont besoin. Notre mission est d'améliorer le bien-être et de fournir une tranquillité d'esprit grâce à des soins pratiques et professionnels livrés directement à votre porte.",
  },
};

export default fr;
