const en = {
  translation: {
    id_1_text: "STELLA MEDICAL",
    id_1_title: "STELLA  registered nurses deliver quality ",
    id_2_title: "care for all your blood tests, vaccines and more, directly to your front door.",
    id_3_text: "OUR SERVICES",
    id_1_Services:"Postpartum care",
    id_1_Services_title:"breastfeeding support / consultation ",
    id_2_Services_title:"newborn support + education ",
    id_3_Services_title:"overnight nursing support",
    id_1_Blood:"Blood Tests",
    id_1_Blood_title:"blood taking ",
    id_2_Blood_title:"stool & urine culture ",
    id_3_Blood_title:"strep screen",
    id_1_taking:"Companion Care + Checkins",
    id_1_taking_title:"Focuses on providing emotional ",
    id_2_taking_title:"support, social interaction, monitoring ",
    id_3_taking_title:"and assistance with daily activities.",
    id_1_other:"other services",
    id_1_other_title:"med administration",
    id_2_other_title:"",
    id_3_other_title:"",
    id_1_button:"learn more",
    id_1_Stella_Fast:"Why use Stella?",
    id_1_Fast:"Fast",
    id_2_Fast:"Reducing hospital wait times, ",
    id_3_Fast:"addresses urgent needs and ",
    id_4_Fast:"improves overall health",
    id_1_Easy:"Easy",
    id_2_Easy:"Accessible right at your ",
    id_3_Easy:"home and convenient for ",
    id_4_Easy:"your schedule ",
    id_1_Safe:"Safe",
    id_2_Safe:"Run by experienced nurse clinicians,",
    id_3_Safe:" who follow the most up to date ",
    id_4_Safe:"clinical practices.",
    id_2_button:"book an appointment",
    id_1_Footer:"contact us",
    id_2_Footer:"Feel free to book an appointment online or contact ",
    id_3_Footer:"us by phone if you have any questions.",
    id_4_Footer:"514-797-7144",
    id_5_Footer:"info@stellamedic.com",
    id_1_About:"About us",
    id_2_About:"Our team of nurse clinicians and compassionate caregivers are committed to delivering exceptional care through our mobile nursing service. With expertise spanning emergency, maternity, homecare, and geriatric care, we deliver personalized, high-quality services tailored to meet each individual's needs. By offering a wide range of services tailored to individual needs, we ensure that patients receive personalized, high-quality support in the comfort of their own homes or wherever they need it. Our mission is to enhance well-being and provide peace of mind with convenient, professional care delivered right to your doorstep.",
  },
};

export default en;
