import React from "react";
import { useTranslation } from "react-i18next";
import Icons from "../../Constants/Icons";


const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="w-full  pt-[250px] pb-[100px] max-[767px]:pb-0" style={{ background: "linear-gradient(180deg, #EBFFFF 61.5%, #25CED1 100%)" }}>
            <div className="container">
                <div className="grid gap-3 items-center " style={{ gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))" }}>
                    <div>
                        <div className="Helvetica text-48 font-bold capitalize text-customBlue">{t("id_1_Footer")}</div>
                        <div className="Helvetica text-25 mt-1">{t("id_2_Footer")}<span className="br block" />{t("id_3_Footer")}</div>
                        <div className="Helvetica text-25 mt-16 font-bold flex gap-8 items-center ml-2 call_icon" >
                            <img src={Icons.Callicon} />
                            {t("id_4_Footer")}</div>
                        <div className="Helvetica text-25 font-bold flex gap-8 items-center ml-2 mt-10 call_icon">
                            <img src={Icons.Maillicon} />
                            {t("id_5_Footer")}</div>

                    </div>
                    <div>
                        <div className="w-full text-right margin-my button_Appointment">
                            <button className="capitalize text-48 Helvetica bg-customBlue text-white px-10  rounded-full py-2 ">{t("id_2_button")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;