import React, { useState, useEffect } from "react";
import i18n from "./i18n";

const LanguageSelector = () => {
  const languages = {
    English: "en",
    Français: "fr",
  };

  const [languageArray] = useState(Object.keys(languages));
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
      i18n.changeLanguage(languages[savedLanguage]);
    }
  }, []);

  const handleLanguageChange = (language) => {
    const languageCode = languages[language];
    setSelectedLanguage(language);
    i18n.changeLanguage(languageCode);
    localStorage.setItem("selectedLanguage", language);
    setIsOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onMouseLeave={() => setIsOpen(false)}
      style={{
        position: "relative",
        display: "inline-block",
        cursor: "pointer",
        minWidth: "50px",
        textAlign: "left",
        // float: "right",
        // left: "-60px",
        // top: "60px",
      }}
    >
      <div
        className="selected"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent:"center",
          justifyContent: "space-between",
          padding: "3px 15px",
          border: `1px solid black`,
          borderRadius: "4px",
          // backgroundColor: "#BD8833",
          color: "black",
        }}
      >
        <div className="" style={{ fontSize: "16px", color: "black" }}>
          {selectedLanguage === "English" ? "En" : "fr"}

        </div>
      
      </div>
      <ul
        style={{
          listStyleType: "none",
          margin: 0,
          padding: 0,
          position: "absolute",
          top: "100%",
          right: "0px",
          backgroundColor: "black",
          border: `1px solid #E0C9AA`,
          borderRadius: "4px",
          boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
          zIndex: 1000,
          display: isOpen ? "block" : "none",
          width:"100px"
        }}
      >
        {languageArray.map((language) => (
          <li
            key={language}
            onClick={() => handleLanguageChange(language)}
            className="text-20"
            style={{
              padding: "8px 12px",
              cursor: "pointer",
              backgroundColor:"black",
              color:"white"
            }}
          >
            {language}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSelector;
