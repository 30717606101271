const Images = {
  bannerhome: require("../Assets/Imgas/home/bannerhome.png"),
  ic_stella: require("../Assets/Imgas/home/ic_stella.png"),
  Rectanglehome: require("../Assets/Imgas/home/Rectanglehome.png"),
  Services: require("../Assets/Imgas/home/Services.png"),
  Services1: require("../Assets/Imgas/home/Services1.png"),
  Services2: require("../Assets/Imgas/home/Services2.png"),
  Services3: require("../Assets/Imgas/home/Services3.png"),
  Fast: require("../Assets/Imgas/home/Fast.png"),
  Fast1: require("../Assets/Imgas/home/Fast1.png"),
  Fast2: require("../Assets/Imgas/home/Fast2.png"),

};
export default Images;
